import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const SideBySide = makeShortcode("SideBySide");
const Suggestion = makeShortcode("Suggestion");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "rtl-and-bidi"
    }}>{`RTL and Bidi`}</h1>
    <h2 {...{
      "id": "what-is-rtl"
    }}>{`What is RTL?`}</h2>
    <p>{`In right-to-left (RTL) languages, script starts from the right of the page and continues to the
left, proceeding from top to bottom for new lines. This can be contrasted with left-to-right writing
systems such as English or other Latin text, where writing starts from the left of the page and
continues to the right. Although RTL script is displayed right-to-left, embedded numbers or text in
other scripts (such as Latin script) still runs left-to-right. Text in other languages, such as
English, can also be bidirectional if it includes excerpts from languages such as Arabic and Hebrew.`}</p>
    <h2 {...{
      "id": "what-is-bidi"
    }}>{`What is Bidi?`}</h2>
    <p>{`Bi-Directional (Bidi) languages refer to the ones that are written and read mainly from
right-to-left (RTL). These languages include Arabic, Hebrew, Persian (Farsi), Azerbaijani, Urdu,
Punjabi (in Pakistan), Pushto, Dari, Uigur, and Yiddish. While these languages follow the
right-to-left direction, they may include text written in Western languages that follows the
left-to-right (LTR) direction. Languages that include both RTL and LTR directionality are called
Bidi languages.`}</p>
    <h2 {...{
      "id": "guidelines"
    }}>{`Guidelines`}</h2>
    <p>{`There is more to meeting RTL and Bidi requirements than flipping your UI's 'x-axis'. For example, in
an interface that supports both text directions, words like left and right get confusing quickly and
are also no longer accurate. Speaking about previous and next elements makes more sense since they
are independent of the interface direction.`}</p>
    <p>{`It is important to `}<strong parentName="p">{`implement RTL and Bidi solutions holistically rather than individually`}</strong>{`. The
following guidelines are listed in order of priority to ensure your product is meeting RTL and Bidi
requirements.`}</p>
    <h3 {...{
      "id": "page-layout"
    }}>{`Page Layout`}</h3>
    <p>{`In general, Bidi languages require the overall page layout and most images to be flipped.`}</p>
    <p><strong parentName="p">{`English (LTR)`}</strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "672px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/25d9ba2b67c5f557aa79c8290fb1a6a4/30d16/rtl_english.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "67.83783783783784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Low-fidelity illustration of a homepage in English. Text reads left to right.",
            "title": "Low-fidelity illustration of a homepage in English. Text reads left to right.",
            "src": "/static/25d9ba2b67c5f557aa79c8290fb1a6a4/30d16/rtl_english.png",
            "srcSet": ["/static/25d9ba2b67c5f557aa79c8290fb1a6a4/1efb2/rtl_english.png 370w", "/static/25d9ba2b67c5f557aa79c8290fb1a6a4/30d16/rtl_english.png 672w"],
            "sizes": "(max-width: 672px) 100vw, 672px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Arabic (Bidi)`}</strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "672px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/67f5eda92ccb2ca1c94377cf77722454/30d16/rtl_arabic.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "67.83783783783784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Low-fidelity illustration of a homepage in Arabic. Text and images have been flipped to read right to left.",
            "title": "Low-fidelity illustration of a homepage in Arabic. Text and images have been flipped to read right to left.",
            "src": "/static/67f5eda92ccb2ca1c94377cf77722454/30d16/rtl_arabic.png",
            "srcSet": ["/static/67f5eda92ccb2ca1c94377cf77722454/1efb2/rtl_arabic.png 370w", "/static/67f5eda92ccb2ca1c94377cf77722454/30d16/rtl_arabic.png 672w"],
            "sizes": "(max-width: 672px) 100vw, 672px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "images-and-icons"
    }}>{`Images and Icons`}</h3>
    <p><strong parentName="p">{`Mirror`}</strong></p>
    <ul>
      <li parentName="ul">{`Icons that imply directionality like back/forward buttons
`}<span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "427px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/34b8c066876d33e81b95e38bf24b50c1/a7c74/rtl-directionality.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "112.43243243243244%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "LTR back Button displayed as an arrow pointing left. RTL back Button displayed as an arrow pointing right. LTR forward Button displayed as an arrow pointing right. RTL forward Button displayed as an arrow pointing left.",
              "title": "LTR back Button displayed as an arrow pointing left. RTL back Button displayed as an arrow pointing right. LTR forward Button displayed as an arrow pointing right. RTL forward Button displayed as an arrow pointing left.",
              "src": "/static/34b8c066876d33e81b95e38bf24b50c1/a7c74/rtl-directionality.png",
              "srcSet": ["/static/34b8c066876d33e81b95e38bf24b50c1/1efb2/rtl-directionality.png 370w", "/static/34b8c066876d33e81b95e38bf24b50c1/a7c74/rtl-directionality.png 427w"],
              "sizes": "(max-width: 427px) 100vw, 427px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></li>
      <li parentName="ul">{`Icons that imply text direction
`}<span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "568px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/74163d89e15ddc5afbc0be8419d3f8a6/10e91/rtl-movement.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "74.5945945945946%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "In a LTR UI, the text direction reads from left to right. Icons with text implications, like checkmarks, are also expected to follow left to right.",
              "title": "In a LTR UI, the text direction reads from left to right. Icons with text implications, like checkmarks, are also expected to follow left to right.",
              "src": "/static/74163d89e15ddc5afbc0be8419d3f8a6/10e91/rtl-movement.png",
              "srcSet": ["/static/74163d89e15ddc5afbc0be8419d3f8a6/1efb2/rtl-movement.png 370w", "/static/74163d89e15ddc5afbc0be8419d3f8a6/10e91/rtl-movement.png 568w"],
              "sizes": "(max-width: 568px) 100vw, 568px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></li>
      <li parentName="ul">{`Icons that imply location of UI elements on the screen`}</li>
      <li parentName="ul">{`Icons representing objects that are meant to be handheld should look like they're being held by
the opposite hand`}</li>
      <li parentName="ul">{`Icons that show forward movement`}</li>
    </ul>
    <p><strong parentName="p">{`Do Not Mirror`}</strong></p>
    <SideBySide mdxType="SideBySide">
      <Suggestion guidance="RTL name and LTR phone number should always be in the correct direction for the language." mdxType="Suggestion">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "280px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/35362d40cb25f02676375c4a1964fd55/908b1/RTL-Bidi-Mirror-Text-Do.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "54.285714285714285%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "RTL name and LTR phone number should always be in the correct direction for the language.",
                "title": "RTL name and LTR phone number should always be in the correct direction for the language.",
                "src": "/static/35362d40cb25f02676375c4a1964fd55/908b1/RTL-Bidi-Mirror-Text-Do.png",
                "srcSet": ["/static/35362d40cb25f02676375c4a1964fd55/908b1/RTL-Bidi-Mirror-Text-Do.png 280w"],
                "sizes": "(max-width: 280px) 100vw, 280px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </Suggestion>
      <Suggestion status="error" guidance="Both RTL and LTR text shouldn't be displayed in reverse order." mdxType="Suggestion">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "280px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/535c497d58f4e4083d2a8ee31177d953/908b1/RTL-Bidi-Mirror-Text-Dont.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "54.285714285714285%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Both RTL and LTR text shouldn't be displayed in reverse order.",
                "title": "Both RTL and LTR text shouldn't be displayed in reverse order.",
                "src": "/static/535c497d58f4e4083d2a8ee31177d953/908b1/RTL-Bidi-Mirror-Text-Dont.png",
                "srcSet": ["/static/535c497d58f4e4083d2a8ee31177d953/908b1/RTL-Bidi-Mirror-Text-Dont.png 280w"],
                "sizes": "(max-width: 280px) 100vw, 280px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </Suggestion>
    </SideBySide>
    <ul>
      <li parentName="ul">{`Text and numbers`}</li>
      <li parentName="ul">{`Icons containing text or numbers`}</li>
      <li parentName="ul">{`Icons and animations that are direction-neutral`}</li>
      <li parentName="ul">{`Icons that wouldn't look different if they were mirrored, like X Buttons or the bookmark star icon`}</li>
      <li parentName="ul">{`Icons that should look the same as LTR, like icons related to code (which is always LTR)`}</li>
      <li parentName="ul">{`Checkmark icons`}</li>
      <li parentName="ul">{`Video/audio player controls`}</li>
      <li parentName="ul">{`Product logos`}</li>
      <li parentName="ul">{`Order of size dimensions (e.g., 1920x1080 should not become 1080x1920)`}</li>
      <li parentName="ul">{`Order of size units (e.g., 10 px should not become px 10 unless the size unit is localizable)`}</li>
      <li parentName="ul">{`Slashes`}</li>
    </ul>
    <SideBySide mdxType="SideBySide">
      <Suggestion guidance="The LTR slash can indicate &ldquo;no trash&rdquo; for both LTR and RTL languages." mdxType="Suggestion">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "164px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/ef40f6e11b76e98594a601d9eb9e793e/2001c/RTL-Bidi-Mirror-Icon-Do.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "The LTR slash can indicate “no trash” for both LTR and RTL languages.",
                "title": "The LTR slash can indicate “no trash” for both LTR and RTL languages.",
                "src": "/static/ef40f6e11b76e98594a601d9eb9e793e/2001c/RTL-Bidi-Mirror-Icon-Do.png",
                "srcSet": ["/static/ef40f6e11b76e98594a601d9eb9e793e/2001c/RTL-Bidi-Mirror-Icon-Do.png 164w"],
                "sizes": "(max-width: 164px) 100vw, 164px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </Suggestion>
      <Suggestion status="error" guidance="A RTL &ldquo;no trash&rdquo; icon doesn't need a mirrored slash." mdxType="Suggestion">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "164px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/74a2693f40e4f1b3d861ac5f8fe691cb/2001c/RTL-Bidi-Mirror-Icon-Dont.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "A RTL \"no trash\" icon doesn't need a mirrored slash.",
                "title": "A RTL \"no trash\" icon doesn't need a mirrored slash.",
                "src": "/static/74a2693f40e4f1b3d861ac5f8fe691cb/2001c/RTL-Bidi-Mirror-Icon-Dont.png",
                "srcSet": ["/static/74a2693f40e4f1b3d861ac5f8fe691cb/2001c/RTL-Bidi-Mirror-Icon-Dont.png 164w"],
                "sizes": "(max-width: 164px) 100vw, 164px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </Suggestion>
    </SideBySide>
    <h3 {...{
      "id": "field-directionality"
    }}>{`Field Directionality`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "426px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3a9254f332d28f62d92b7820cb2334d1/531e1/rtl_input.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "161.35135135135135%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Text should start on the right side of the input for RTL languages.",
            "title": "Text should start on the right side of the input for RTL languages.",
            "src": "/static/3a9254f332d28f62d92b7820cb2334d1/531e1/rtl_input.png",
            "srcSet": ["/static/3a9254f332d28f62d92b7820cb2334d1/1efb2/rtl_input.png 370w", "/static/3a9254f332d28f62d92b7820cb2334d1/531e1/rtl_input.png 426w"],
            "sizes": "(max-width: 426px) 100vw, 426px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul">{`The directionality of input fields is determined by the current locale`}</li>
      <li parentName="ul">{`Display text based on the directionality of the characters in the field`}</li>
      <li parentName="ul">{`The directionality of text input fields changes based on the current character's directionality`}
        <ul parentName="li">
          <li parentName="ul">{`RTL comma + space influences the word following them to be in RTL order`}</li>
          <li parentName="ul">{`English comma + space influences the word following them to be in LTR order`}</li>
        </ul>
      </li>
      <li parentName="ul">{`The cursor is determined by the current character's directionality`}
        <ul parentName="li">
          <li parentName="ul">{`In the example below, the Latin space character between "456" and "7899" has the directionality
of LTR, so the space character and "7899" is placed to the right side of "456".`}</li>
        </ul>
      </li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "654px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/052c10f74df9e921a1d2fead41189015/68e9c/rtl_mix.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "20.27027027027027%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Rich Text Editor with a phone number and Arabic text to demonstrate mixed RTL and LTR characters.",
            "title": "Rich Text Editor with a phone number and Arabic text to demonstrate mixed RTL and LTR characters.",
            "src": "/static/052c10f74df9e921a1d2fead41189015/68e9c/rtl_mix.png",
            "srcSet": ["/static/052c10f74df9e921a1d2fead41189015/1efb2/rtl_mix.png 370w", "/static/052c10f74df9e921a1d2fead41189015/68e9c/rtl_mix.png 654w"],
            "sizes": "(max-width: 654px) 100vw, 654px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "keyboard-navigation"
    }}>{`Keyboard Navigation`}</h3>
    <ul>
      <li parentName="ul">{`Standard keyboard navigation should be followed using the Tab key:`}
        <ul parentName="li">
          <li parentName="ul">{`Pressing Tab focuses on the next element, while pressing Shift + Tab will focus on the previous
element.`}</li>
          <li parentName="ul">{`The order of focusing is determined implicitly (based on physical order) or explicitly (based on
tab index).`}</li>
          <li parentName="ul">{`Tabbing is not linear but cyclical.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`The tab order for Bidi languages is RTL, right-to-left (horizontally) and top-to-bottom
(vertically).`}</li>
      <li parentName="ul">{`The tab order for left-to-right (LTR) languages including English is left-to-right (horizontally)
and top to bottom (vertically).`}</li>
      <li parentName="ul">{`Keyboard navigation with left and right arrow keys stays the same independent of the language /
locale.`}</li>
    </ul>
    <p><strong parentName="p">{`English (LTR)`}</strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "672px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/cc18331dda9b29c99ca1e1e3ce7d8853/30d16/rtl_english_tab.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.27027027027026%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Low-fidelity illustration of a homepage in English. Annotations demonstrate tabbing order of items for LTR languages starting from the top-left of the page.",
            "title": "Low-fidelity illustration of a homepage in English. Annotations demonstrate tabbing order of items for LTR languages starting from the top-left of the page.",
            "src": "/static/cc18331dda9b29c99ca1e1e3ce7d8853/30d16/rtl_english_tab.png",
            "srcSet": ["/static/cc18331dda9b29c99ca1e1e3ce7d8853/1efb2/rtl_english_tab.png 370w", "/static/cc18331dda9b29c99ca1e1e3ce7d8853/30d16/rtl_english_tab.png 672w"],
            "sizes": "(max-width: 672px) 100vw, 672px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Arabic (Bidi)`}</strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "672px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e90024a09d7cba0c1ccd9967d8c9177e/30d16/rtl_arabic_tab.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.27027027027026%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Low-fidelity illustration of a homepage in Arabic. Annotations demonstrate tabbing order of items for Bidi languages starting from the top-right of the page.",
            "title": "Low-fidelity illustration of a homepage in Arabic. Annotations demonstrate tabbing order of items for Bidi languages starting from the top-right of the page.",
            "src": "/static/e90024a09d7cba0c1ccd9967d8c9177e/30d16/rtl_arabic_tab.png",
            "srcSet": ["/static/e90024a09d7cba0c1ccd9967d8c9177e/1efb2/rtl_arabic_tab.png 370w", "/static/e90024a09d7cba0c1ccd9967d8c9177e/30d16/rtl_arabic_tab.png 672w"],
            "sizes": "(max-width: 672px) 100vw, 672px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "additional-considerations"
    }}>{`Additional Considerations`}</h2>
    <h3 {...{
      "id": "displaying-numbers"
    }}>{`Displaying Numbers`}</h3>
    <ul>
      <li parentName="ul">{`For consistency, use Latin to display numbers.`}</li>
      <li parentName="ul">{`Hebrew users use Latin numbers except for the rarely-used Hebrew calendar.`}</li>
      <li parentName="ul">{`Arabic users use both Latin and Hindi numbers, but Latin numbers are much more common.`}</li>
      <li parentName="ul">{`Any numbers including Latin and Hindi numbers are to be used in text input fields freely.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "520px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/312225597700b690512bbe95c43b1f77/69902/rtl-numbers.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "38.91891891891891%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Arabic users use both Latin and Hindi numbers, but Latin numbers are much more common.",
            "title": "Arabic users use both Latin and Hindi numbers, but Latin numbers are much more common.",
            "src": "/static/312225597700b690512bbe95c43b1f77/69902/rtl-numbers.png",
            "srcSet": ["/static/312225597700b690512bbe95c43b1f77/1efb2/rtl-numbers.png 370w", "/static/312225597700b690512bbe95c43b1f77/69902/rtl-numbers.png 520w"],
            "sizes": "(max-width: 520px) 100vw, 520px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "cursor-position"
    }}>{`Cursor Position`}</h3>
    <ul>
      <li parentName="ul">{`For LTR direction, the cursor is placed to the right after displaying the LTR character.`}</li>
      <li parentName="ul">{`For RTL direction, the cursor is placed to the left after displaying the RTL character.`}</li>
    </ul>
    <h3 {...{
      "id": "special-characters"
    }}>{`Special Characters`}</h3>
    <ul>
      <li parentName="ul">{`Arabic question mark: ؟`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      